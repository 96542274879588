export default {
    title: '电子合同后台',
    version: '1.0.0',
    isIndependentDeployment: false, //是否为独立部署 默认为false
    elseUrl: 'http://guanwang.puhuijishu.xyz',
    path: "wss://cscontract.hulianzaowu.top:9501",//webscoket
    appletsBaseURL: 'https://wxapplets.puhuijishu.top/api',
    // apiUrl:'https://www.anxcar.cn/', //安芯签线上    
    // apiUrl:'http://ht.yinkeyunqian.cn/', //银客云签
    // apiUrl:'https://dzq.315gogo.com', //独立部署 邀请王云签 成名云签电子合同    
    // apiUrl:'http://hetong.com',
    // apiUrl:'https://dianziqian.tiaojiezhan.com/',

    // apiUrl: 'http://192.168.1.201:65',//本地
    // apiUrl:'http://192.168.1.199:8088', //四哥本地 
    // apiUrl: 'http://cscontract.puhuijishu.top', //测试服(现)
    // apiUrl: 'https://cscontract.hulianzaowu.top', //测试服（老）
    // apiUrl: 'https://susuyunqian.hulianzaowu.com/', //线上   
    // apiUrl: 'https://www.anxcar.cn/', //线上    //17719496114

    // apiUrl:'https://cs1contract.puhuijishu.top', //独立域名测试服    
    apiUrl: `${location.origin}`
}