import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/iview.js";
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Meta from 'vue-meta'   

// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;
Vue.use(Meta)
Vue.use(ViewUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
