/**管理中心 */
export default {
    name: 'management',
    path: 'management',
    meta: {
        title: '管理中心'
    },
    component: () => import('@/pages/index.vue'),
    children: [
        {
            name: 'management-contract',
            path: '/management/contract',
            meta: { title: '合同管理' },
            component: () => import('@/pages/management/contract.vue')
        },
        {
            name: 'management-approval',
            path: '/management/approval',
            meta: { title: '批签管理' },
            component: () => import('@/pages/management/approval.vue')
        },
        {
            name: 'management-authentication',
            path: '/management/authentication',
            meta: { title: '认证管理' },
            component: () => import('@/pages/management/authentication.vue')
        },
        {
            name: 'management-mould',
            path: '/management/mould',
            meta: { title: '模板管理' },
            component: () => import('@/pages/management/mould.vue')
        },
        {
            name: 'management-recyclebin',
            path: '/management/recyclebin',
            meta: { title: '回收站' },
            component: () => import('@/pages/management/recyclebin.vue')
        }
    ]
}

