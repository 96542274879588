import axios from 'axios';
import { Message } from 'view-design';
import config from '@/config';
import router from '@/router'
import { getCookies, removeCookies } from '@/utils/helper'
import store from '@/store/index'

const service = axios.create({
    baseURL: config.apiUrl + "/adminapi",
    timeout: 150000 // 请求超时时间
})


service.interceptors.request.use(
    config => {
        const token = getCookies('token')
        if (token) {
            store.commit('changeGlobalSpin', true)
            config.headers['authori-zation'] = token
        }
        return config;
    },
    error => {
        store.commit('changeGlobalSpin', false)
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    response => {
        store.commit('changeGlobalSpin', false)
        let status = response.data ? response.data.code : 0
        const code = status
        switch (code) {
            case 200:
                return response.data
            case 400:
                return Promise.reject(response.data || { msg: '未知错误' })
            case 400011:
            case 500:
            case 400012:
                return Promise.reject({ msg: '系统异常' })
            case 410000:
            case 410001:
            case 410002:
                localStorage.clear()
                removeCookies('token')
                removeCookies('expires_time')
                removeCookies('uuid')
                router.replace({ path: '/' })
                break
            default:
                break
        }
    },
    error => {
        store.commit('changeGlobalSpin', false)
        if (typeof error.response == "undefined") {
            Message.error("服务器异常");
        }
        return Promise.reject(error);
    }
)

export default service;
