import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: {},
        menuSecond: [],
        auth: [],
        activeType: '',
        version: '',
        settingMenu: [],
        identity: "",
        centerMenu: [],//唐 应用中心
        isGlobalSpin: false,
        isPolling: true,
        recyclingCount: 0 //合同管理回收站数量
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        reset(state) {
            state.userInfo = {};
        },
        setMenus(state, menuSecond) {
            state.menuSecond = menuSecond
        },
        setType(state, activeType) {
            state.activeType = activeType
        },
        setVersion(state, version) {
            state.version = version
        },
        setSetting(state, settingMenu) {
            state.settingMenu = settingMenu
        },
        setCenter(state, centerMenu) {
            state.centerMenu = centerMenu
        },
        resetMenus(state) {
            state.menuSecond = [];
        },
        setAuth(state, auth) {
            state.auth = auth
        },
        //身份识别 唐+
        setIdentity(state, identity) {
            state.identity = identity
        },
        changeGlobalSpin(state, show) {
            state.isGlobalSpin = show;
        },
        changePollingSpin(state, show) {
            state.isPolling = show;
        },
        //回收站合同数量
        SET_RECYCLEBIN_COUNT(state, count) {
            state.recyclingCount = count
        },
    },
    getters: {

    },
    actions: {
        resetAction({ commit }) {
            commit('reset')
        },
        resetMen({ commit }) {
            commit('resetMenus')
        }
    },
    modules: {

    },
    plugins: [createPersistedState()]
})
